import React from 'react';

import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';

import {
  validate,
  requiredString,
  requiredNumber,
  conditionalString,
  conditionalNumber,
} from '../utils/validate';

const baseRadioOptions = [
  {
    id: 1,
    value: 'yes',
    label: 'Yes',
  },
  {
    id: 2,
    value: 'no',
    label: 'No',
  },
];

const baseRadioOptionsBool = [
  {
    id: 1,
    value: 'true',
    label: 'Yes',
  },
  {
    id: 2,
    value: 'false',
    label: 'No',
  },
];

const loginForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email',
    prefix: <EmailIcon />,
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    label: 'Password',
    prefix: <LockIcon />,
  },
];

const resetForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email',
    prefix: <EmailIcon />,
  },
];

const registerForm = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    label: 'Your name',
  },
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Your email address',
  },
  {
    id: 'agreeTerms',
    name: 'agreeTerms',
    type: 'checkbox',
    label: (
      <>
        I agree to the{' '}
        <a
          href="https://finmo.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://finmo.co.uk/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

const registerFormLanding = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    label: 'Your name',
  },
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Your email address',
  },
  {
    id: 'agreeTerms',
    name: 'agreeTerms',
    type: 'checkbox',
    label: (
      <>
        I agree to the{' '}
        <a
          href="https://finmo.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://finmo.co.uk/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

const optimiserStep1Form = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email address',
  },

  {
    id: 'agreeTerms',
    name: 'agreeTerms',
    type: 'checkbox',
    label: (
      <>
        I agree to the{' '}
        <a
          href="https://finmo.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://finmo.co.uk/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

const optimiserStep1WithNameForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email address',
  },

  {
    id: 'name',
    name: 'name',
    type: 'name',
    label: 'Your name',
  },

  {
    id: 'agreeTerms',
    name: 'agreeTerms',
    type: 'checkbox',
    label: (
      <>
        I agree to the{' '}
        <a
          href="https://finmo.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://finmo.co.uk/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

const optimiserStep2Form = [
  {
    id: 'newPassword',
    name: 'newPassword',
    type: 'password',
    label: 'Password',
  },
];

const optimiserStep3Form = [
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'text',
    label: 'Phone number',
  },
];

const optimiserStep4Form = [
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    label: 'First name',
  },

  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    label: 'Last name',
  },

  {
    id: 'file',
    name: 'file',
    type: 'file',
    label: 'Last Self Assessment',
  },

  {
    id: 'additionalNotes',
    name: 'additionalNotes',
    type: 'textarea',
    label: 'Additional Information',
    optional: true,
  },
];

const optimiserStep4FormWithoutName = [
  {
    id: 'file',
    name: 'file',
    type: 'file',
    label: 'Last Self Assessment',
  },

  {
    id: 'additionalNotes',
    name: 'additionalNotes',
    type: 'textarea',
    label: 'Additional Information',
    optional: true,
  },
];

const changePasswordForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email address',
  },
  {
    id: 'newPassword',
    name: 'newPassword',
    type: 'passwordNew',
    label: 'New password',
  },
];

const profileForm = {
  fields: [
    {
      id: 'email',
      name: 'email',
      type: 'email',
      label: 'Email',
      disabled: true,
    },
    {
      id: 'utrNumber',
      name: 'utrNumber',
      type: 'text',
      label: 'UTR Number',
    },
    {
      id: 'firstName',
      name: 'firstName',
      type: 'text',
      label: 'First name',
    },
    {
      id: 'lastName',
      name: 'lastName',
      type: 'text',
      label: 'Last name',
    },
    {
      id: 'address',
      name: 'address',
      type: 'text',
      label: 'Address',
    },
    {
      id: 'city',
      name: 'city',
      type: 'text',
      label: 'City',
    },
    {
      id: 'country',
      name: 'country',
      type: 'text',
      label: 'Country',
    },
    {
      id: 'postCode',
      name: 'postCode',
      type: 'text',
      label: 'Post code',
    },
    {
      id: 'dateOfBirth',
      name: 'dateOfBirth',
      type: 'date',
      label: 'Date of Birth',
    },
    {
      id: 'phoneNumber',
      name: 'phoneNumber',
      type: 'text',
      label: 'Phone Number',
    },
    {
      id: 'nationalInsuranceNumber',
      name: 'nationalInsuranceNumber',
      type: 'text',
      label: 'NI Number',
    },
  ],
  validation: validate({
    firstName: requiredString,
    lastName: requiredString,
    address: requiredString,
    city: requiredString,
    country: requiredString,
    postCode: requiredString.max(9, 'This is not a valid UK post code'),
    dateOfBirth: requiredString,
    phoneNumber: requiredString.max(15, 'This is not a valid UK phone number'),
    nationalInsuranceNumber: requiredString
      .min(
        9,
        'National Insurance Number should be a combination of 9 numbers and letters',
      )
      .max(
        9,
        'National Insurance Number should be a combination of 9 numbers and letters',
      ),
    utrNumber: requiredString
      .min(10, 'Unique tax reference should be a 10 digit number')
      .max(10, 'Unique tax reference should be a 10 digit number'),
  }),
};

const overviewProfileForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email',
    disabled: true,
  },
  {
    id: 'utrNumber',
    name: 'utrNumber',
    type: 'text',
    label: 'UTR Number',
    small: true,
  },
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    label: 'First name',
  },
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    label: 'Last name',
  },
  {
    id: 'address',
    name: 'address',
    type: 'text',
    label: 'Address',
  },
  {
    id: 'city',
    name: 'city',
    type: 'text',
    label: 'City',
  },
  {
    id: 'country',
    name: 'country',
    type: 'text',
    label: 'Country',
  },
  {
    id: 'postCode',
    name: 'postCode',
    type: 'text',
    label: 'Post code',
    small: true,
  },
  {
    id: 'dateOfBirth',
    name: 'dateOfBirth',
    type: 'date',
    label: 'Date of Birth',
    small: true,
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'text',
    label: 'Phone Number',
    small: true,
  },
  {
    id: 'nationalInsuranceNumber',
    name: 'nationalInsuranceNumber',
    type: 'text',
    label: 'NI Number',
    small: true,
  },
  {
    id: 'title1',
    name: 'title1',
    type: 'title',
    label: 'General tax options',
  },
  {
    id: 'isScottishTaxPayer',
    name: 'isScottishTaxPayer',
    type: 'radio',
    label: 'I am a Scottish Taxpayer',
    helpText:
      "If you live in Scotland, you pay a different tax rate on income. Scottish income tax applies to your wages, pension and most other taxable income. You'll pay the same tax as the rest of the UK on dividends and savings interest.",
    options: baseRadioOptionsBool,
    mainField: true,
  },

  {
    id: 'addressChangedInLastYear',
    name: 'addressChangedInLastYear',
    type: 'radio',
    label: 'I changed my address in the last year',
    options: baseRadioOptionsBool,
    mainField: true,
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'addressChangeDate',
          name: 'addressChangeDate',
          type: 'date',
          label: 'Address Change Date',
          small: true,
        },
      ],
    },
  },

  {
    id: 'refundRecievedLastYear',
    name: 'refundRecievedLastYear',
    type: 'radio',
    label: 'I received a refund last year',
    options: baseRadioOptionsBool,
    mainField: true,
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'refundAmount',
          name: 'refundAmount',
          type: 'text',
          label: 'Amount',
          prefix: '£',
          small: true,
        },
      ],
    },
  },

  {
    id: 'paymentOnAccountLastYear',
    name: 'paymentOnAccountLastYear',
    type: 'radio',
    label: 'I made payments on account last year ',
    options: baseRadioOptionsBool,
    helpText:
      "'Payments on account' are advance payments towards your tax bill.",
    mainField: true,
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'paymentOnAccountLastYearWithTax',
          name: 'paymentOnAccountLastYearWithTax',
          type: 'text',
          label: 'Amount paid with last year’s tax payment',
          prefix: '£',
          small: true,
        },
        {
          id: 'paymentOnAccountLastYearJune',
          name: 'paymentOnAccountLastYearJune',
          type: 'text',
          label: 'Amount paid in July',
          prefix: '£',
          small: true,
        },
      ],
    },
  },
];

const taxSettingsForm = [
  {
    id: 'isMarried',
    name: 'isMarried',
    type: 'radio',
    options: baseRadioOptionsBool,
    mainField: true,
    label: 'I am married or in a civil partnership.',
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'lowIncomePartner',
          name: 'lowIncomePartner',
          type: 'radio',
          label: 'My Partner makes less than 12,500 per year.',
          options: baseRadioOptionsBool,
        },
      ],
    },
  },
  {
    id: 'hasPension',
    name: 'hasPension',
    type: 'radio',
    options: baseRadioOptionsBool,
    mainField: true,
    label: 'Pension: I contribute towards a pension.',
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'pensionAmount',
          name: 'pensionAmount',
          type: 'text',
          label: 'Monthly Contribution:',
          prefix: '£',
          small: true,
        },
      ],
    },
  },
  {
    id: 'hasCapitalGains',
    name: 'hasCapitalGains',
    type: 'radio',
    options: baseRadioOptionsBool,
    mainField: true,
    label:
      'Capital Gains: I sold a non-real estate asset and made capital gains.',
    subFields: {
      value: 'true',
      fields: [
        {
          id: 'capitalGainsAmountLastYear',
          name: 'capitalGainsAmountLastYear',
          type: 'text',
          label: 'Amount (2018 / 2019):',
          prefix: '£',
          small: true,
        },
        {
          id: 'capitalGainsAmount',
          name: 'capitalGainsAmount',
          type: 'text',
          label: 'Amount (2019 / 2020):',
          prefix: '£',
          small: true,
        },
      ],
    },
  },
];

const businessSettingsForm = [
  {
    id: 'industryId',
    name: 'industryId',
    type: 'select',
    label: 'Industry:',
    options: [],
  },
  {
    id: 'yearlyIncome',
    name: 'yearlyIncome',
    type: 'income',
    label: 'Estimated Income:',
    prefix: '£',
    helpText:
      'Estimated income is only for estimating your end of year taxes and won’t impact your Self Assessment',
  },
  {
    id: 'yearlyExpenses',
    name: 'yearlyExpenses',
    type: 'income',
    label: 'Estimated Expenses:',
    prefix: '£',
    helpText:
      'Estimated expense is only for estimating your end of year taxes and won’t impact your Self Assessment',
  },
];

const taxRelief = {
  fields: [
    {
      id: 'title1',
      name: 'title1',
      type: 'title',
      label: 'Child Benefits',
    },
    {
      id: 'responsibleForChildren',
      name: 'responsibleForChildren',
      type: 'radio',
      label:
        'I am responsible for one or more children under the age of 16 and elegible to claim Child Benefit',
      options: baseRadioOptions,
      helpText:
        'If you or your partner make less than £50,000 you may be elegible to claim Child Benefit',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'numberOfChildren',
            name: 'numberOfChildren',
            type: 'text',
            label: 'How many children total are you responsible for?',
            narrow: true,
            helpText:
              'Enter the number of childer under the age of 16 or under 20 if they stay in approved education or training',
            small: true,
          },

          {
            id: 'receiveChildBenefits',
            name: 'receiveChildBenefits',
            type: 'select',
            label: 'Did you receive Child Benefits?',
            narrow: true,
            helpText:
              'Child Benefits give you National Insurance credits which count towards your State Pension',
            options: [
              {
                id: '1',
                label: 'I receive Child Benefits and don’t expect to pay taxes',
                value: 'I receive Child Benefits and don’t expect to pay taxes',
              },
              {
                id: '2',
                label: 'I receive Child Benefits and expect to pay taxes',
                value: 'I receive Child Benefits and expect to pay taxes',
              },
              {
                id: '3',
                label: 'I have opted out of Child Benefits',
                value: 'I have opted out of Child Benefits',
              },
            ],
          },
          {
            id: 'stoppedReceivingChildBenefits',
            name: 'stoppedReceivingChildBenefits',
            type: 'radio',
            label: 'I stopped getting Child Benefits this during this tax year',
            options: baseRadioOptions,
            helpText: 'If you stopped receiving Child Benefits this year.',
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'dateStoppedReceivingChildBenefits',
                  name: 'dateStoppedReceivingChildBenefits',
                  type: 'date',
                  label: 'Date you stopped getting Child Benefits',
                  helpText: 'Enter the date you stopped getting Child Benefits',
                },
              ],
            },
          },
        ],
      },
    },
    {
      id: 'title2',
      name: 'title2',
      type: 'title',
      label: 'Pension',
    },
    {
      id: 'hasPension',
      name: 'hasPension',
      type: 'radio',
      label: 'I contribute to a Pension or SIPP',
      options: baseRadioOptions,
      helpText:
        'If you contribute to a personal pension through a PAYE job or a self-invested personal pension (SIPP)',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'pensionType',
            name: 'pensionType',
            type: 'select',
            label: 'Type of Pension',
            helpText: 'Please enter the type of pension you contribute to',
            options: [
              {
                id: '1',
                label: 'SIPP (grossed up by provider)',
                value: 'SIPP (grossed up by provider)',
              },
              {
                id: '2',
                label: 'SIPP (not grossed up by provider)',
                value: 'SIPP (not grossed up by provider)',
              },
              {
                id: '3',
                label: 'Employer Pension (paid before tax - this is normal)',
                value: 'Employer Pension (paid before tax - this is normal)',
              },
              {
                id: '4',
                label: 'Employer Pension (paid after tax - not as normal)',
                value: 'Employer Pension (paid after tax - not as normal)',
              },
              {
                id: '5',
                label: 'Foreign Pension',
                value: 'Foreign Pension',
              },
              {
                id: '6',
                label: 'Other (add details below)',
                value: 'Other (add details below)',
              },
            ],
            subFields: {
              value:
                'SIPP (grossed up by provider),SIPP (not grossed up by provider),Foreign Pension',
              fields: [
                {
                  id: 'pensionAmount',
                  name: 'pensionAmount',
                  type: 'text',
                  label: 'Amount paid to pension in the tax year',
                  prefix: '£',
                  small: true,
                },
              ],
            },
            altSubFields: {
              value: 'Other (add details below)',
              fields: [
                {
                  id: 'pensionDetails',
                  name: 'pensionDetails',
                  type: 'textarea',
                  label: 'Pension details',
                  helpText:
                    'If your accountant needs to know any additional details about your pension',
                },
              ],
            },
          },
          {
            id: 'contributeToMultiplePensions',
            name: 'contributeToMultiplePensions',
            type: 'radio',
            label: 'I contribute to more than one pension',
            helpText:
              'If you contribute to more than one pension - for instance a foreign pension and a SIPP',
            options: baseRadioOptions,
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'additionalInformation',
                  name: 'additionalInformation',
                  type: 'textarea',
                  label: 'Input addtional pension information here',
                  helpText:
                    'If your accountant needs to know any additional details about your pension',
                },
              ],
            },
          },
        ],
      },
    },
    {
      id: 'title3',
      name: 'title3',
      type: 'title',
      label: 'Donations',
    },
    {
      id: 'donationsMade',
      name: 'donationsMade',
      type: 'radio',
      label: 'I made donations during this tax year',
      helpText:
        'Donations to charity from individuals are tax free. You can get tax relief if you donate through Gift Aid or from your wages or pension.',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'donationAmount',
            name: 'donationAmount',
            type: 'text',
            label: 'Amount',
            prefix: '£',
            small: true,
          },
          {
            id: 'paymentsToGiftAid',
            name: 'paymentsToGiftAid',
            type: 'radio',
            label: 'I made payments to gift-aid',
            helpText:
              'Charities and community amateur sports clubs (CASCs) can register with HM Revenue and Customs (HMRC) to be part of the Gift Aid scheme.',
            options: baseRadioOptions,
          },
          {
            id: 'lastYearGiftAid',
            name: 'lastYearGiftAid',
            type: 'radio',
            label:
              'I made gift-aid payments last tax year that should count for this tax year',

            helpText:
              'You made gift aid payments last year that should be counted towards this tax year',
            options: baseRadioOptions,
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'lastYearGiftAidAmount',
                  name: 'lastYearGiftAidAmount',
                  type: 'text',
                  label: 'Amount',
                  prefix: '£',
                  small: true,
                },
                {
                  id: 'notification',
                  name: 'notification',
                  type: 'notification',
                  label:
                    'Note: If you made gift-aid payments during this tax year, please ensure transactions are listed on the transactions page.',
                },
              ],
            },
          },
          {
            id: 'giftAidAfterTaxYear',
            name: 'giftAidAfterTaxYear',
            type: 'radio',
            label:
              'I made gift-aid payments after the end of this tax year that should be counted for this tax year',

            helpText:
              'You made gift aid payments after the end of the most recent tax year that should be counted for this tax year',
            options: baseRadioOptions,
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'giftAidAfterTaxYearAmount',
                  name: 'giftAidAfterTaxYearAmount',
                  type: 'text',
                  label: 'Amount',
                  prefix: '£',
                  small: true,
                },
              ],
            },
          },
          {
            id: 'otherGifts',
            name: 'otherGifts',
            type: 'radio',
            label:
              'I gifted shares, securities, land or investments to charity',

            helpText: 'You gifted an asset other than money to gift aid.',
            options: baseRadioOptions,
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'otherGiftsDetails',
                  name: 'otherGiftsDetails',
                  type: 'textarea',
                  label: 'Details of gift(s)',
                  helpText: 'Enter the details of any assets you gifted.',
                },
              ],
            },
          },
        ],
      },
    },

    {
      id: 'title4',
      name: 'title4',
      type: 'title',
      label: 'Student Loan',
    },
    {
      id: 'employerDeductedStudentLoan',
      name: 'employerDeductedStudentLoan',
      type: 'radio',
      label: 'My employer deducted student loan payments',
      helpText:
        'You owe student loans and your employed deducted your student loan payments',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'employerDeductedStudentLoanAmount',
            name: 'employerDeductedStudentLoanAmount',
            type: 'text',
            label: 'Amount your employer deducted in Studen Loan Payments',
            helpText: 'Enter the amount deducted for the year.',
            prefix: '£',
            small: true,
          },
          {
            id: 'studentLoadPaidInNextTwoYears',
            name: 'studentLoadPaidInNextTwoYears',
            type: 'radio',
            label: 'My student loans will be paid off in the next two years',
            helpText:
              'Your student loans will be paid off within the following two years from the end of the filing tax year.',
            options: baseRadioOptions,
          },
        ],
      },
    },

    {
      id: 'title5',
      name: 'title5',
      type: 'title',
      label: 'Marriage',
    },
    {
      id: 'marriage',
      name: 'marriage',
      type: 'radio',
      label:
        'I am married or in a civil partnership and want to transfer my tax free allowance',

      helpText:
        'Marriage Allowance lets you transfer £1,250 of your Personal Allowance to your husband, wife or civil partner. This can reduce their tax by up to £250 every tax year (6 April to 5 April the next year). To benefit as a couple, you need to earn less than your partner and have an income of £12,500 or less. Your partner’s income must be between £12,501 and £50,000 (£43,430 in Scotland) for you to be eligible.',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'info',
            name: 'info',
            type: 'info',
            label:
              'Please enter your Partners information for whom you wish to transfer your tax free allowance to.',
          },
          {
            id: 'partnerName',
            name: 'partnerName',
            type: 'text',
            label: 'Name',
          },
          {
            id: 'dateOfBirth',
            name: 'dateOfBirth',
            type: 'date',
            label: 'Date of Birth',
            small: true,
          },
          {
            id: 'nationalInsuranceNumber',
            name: 'nationalInsuranceNumber',
            type: 'text',
            label: 'NI Number',
            small: true,
          },

          {
            id: 'dateOfMarriage',
            name: 'dateOfMarriage',
            type: 'date',
            label: 'Date of Marriage or Civil Partnership',
            small: true,
          },
        ],
      },
    },

    {
      id: 'title6',
      name: 'title6',
      type: 'title',
      label: 'CIS',
    },

    {
      id: 'cis',
      name: 'cis',
      type: 'radio',
      label:
        'I have had Construction Industry Scheme (CIS) deductions taken from my payments by contractors',

      helpText:
        'Under the Construction Industry Scheme (CIS), contractors deduct money from a subcontractor’s payments and pass it to HM Revenue and Customs (HMRC). The deductions count as advance payments towards the subcontractor’s tax and National Insurance.',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'cisAmount',
            name: 'cisAmount',
            type: 'text',
            label:
              'Amount of CIS deductions taken from your payments by contractors',

            helpText:
              'Enter the total amount of CIS deductions taken from your payments.',
            prefix: '£',
            small: true,
          },
        ],
      },
    },
    {
      id: 'title7',
      name: 'title7',
      type: 'title',
      label: 'Blind Allowance',
    },
    {
      id: 'blindAllowance',
      name: 'blindAllowance',
      type: 'radio',
      label: 'I, or my partner is blind',
      helpText:
        'Blind Person’s Allowance is an extra amount of tax-free allowance.',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'authorityName',
            name: 'authorityName',
            type: 'text',
            label: 'Name of local authority or register',
            small: true,
          },
          {
            id: 'useSpoucesAllowance',
            name: 'useSpoucesAllowance',
            type: 'select',
            label: 'Transfer your allowance',
            options: [
              {
                id: 1,
                value: 'Dont transfer allowance',
                label: 'Dont transfer allowance',
              },
              {
                id: 2,
                value: 'I want my spouse or civil partner’s allowance',
                label: 'I want my spouse or civil partner’s allowance',
              },
              {
                id: 3,
                value: 'I want to give my spouse or civil partner my allowance',
                label: 'I want to give my spouse or civil partner my allowance',
              },
            ],
          },
        ],
      },
    },
  ],
  validation: validate({
    responsibleForChildren: requiredString,
    numberOfChildren: conditionalNumber('responsibleForChildren', 'yes'),
    receiveChildBenefits: conditionalString('responsibleForChildren', 'yes'),
    stoppedReceivingChildBenefits: conditionalString(
      'responsibleForChildren',
      'yes',
    ),
    dateStoppedReceivingChildBenefits: conditionalString(
      'stoppedReceivingChildBenefits',
      'yes',
    ),

    hasPension: requiredString,
    pensionType: conditionalString('hasPension', 'yes'),
    contributeToMultiplePensions: conditionalString('hasPension', 'yes'),

    donationsMade: requiredString,
    paymentsToGiftAid: conditionalString('donationsMade', 'yes'),
    lastYearGiftAid: conditionalString('donationsMade', 'yes'),
    lastYearGiftAidAmount: conditionalNumber('lastYearGiftAid', 'yes'),
    giftAidAfterTaxYear: conditionalString('donationsMade', 'yes'),
    giftAidAfterTaxYearAmount: conditionalNumber('giftAidAfterTaxYear', 'yes'),
    otherGifts: conditionalString('donationsMade', 'yes'),
    otherGiftsDetails: conditionalString('otherGifts', 'yes'),

    employerDeductedStudentLoan: requiredString,
    employerDeductedStudentLoanAmount: conditionalNumber(
      'employerDeductedStudentLoan',
      'yes',
    ),
    studentLoadPaidInNextTwoYears: conditionalString(
      'employerDeductedStudentLoan',
      'yes',
    ),

    marriage: requiredString,
    partnerName: conditionalString('marriage', 'yes'),
    dateOfBirth: conditionalString('marriage', 'yes'),
    nationalInsuranceNumber: conditionalString('marriage', 'yes'),
    dateOfMarriage: conditionalString('marriage', 'yes'),

    cis: requiredString,
    cisAmount: conditionalNumber('cis', 'yes'),

    blindAllowance: requiredString,
    authorityName: conditionalString('blindAllowance', 'yes'),
    useSpoucesAllowance: conditionalString('blindAllowance', 'yes'),
  }),
};

const freelance = {
  fields: [
    {
      id: 'businessDescription',
      name: 'businessDescription',
      type: 'textarea',
      label: 'Description of business',
      helpText: 'Enter a description of your business.',
    },
    {
      id: 'booksDate',
      name: 'booksDate',
      type: 'date',
      label: 'Date Books are made up to:',
      helpText:
        'Normally this is 5th of April unless you have changed it with HMRC',
      small: true,
    },
    {
      id: 'businessAddressSameAsPersonal',
      name: 'businessAddressSameAsPersonal',
      type: 'radio',
      options: baseRadioOptions,
      label: 'My business address is the same as my personal address',
      helpText:
        'If you work mainly from home, then your business address is the same as your personal.',
      mainField: true,
      subFields: {
        value: 'no',
        fields: [
          {
            id: 'businessAddress',
            name: 'businessAddress',
            type: 'text',
            label: 'Address',
            helpText: 'Enter your address',
          },
          {
            id: 'businessCity',
            name: 'businessCity',
            type: 'text',
            label: 'City',
            helpText: 'Enter your city',
            small: true,
          },
          {
            id: 'businessPostCode',
            name: 'businessPostCode',
            type: 'text',
            label: 'Postal Code',
            helpText: 'Enter your postal code',
            small: true,
          },
        ],
      },
    },
    {
      id: 'businessAddressChanged',
      name: 'businessAddressChanged',
      type: 'radio',
      options: baseRadioOptions,
      label:
        'My business name, address or postcode has changed in the last 12 months',

      helpText:
        'Let HRMC know if anything with regards to your business has changed so it knows where to send future correspondence.',
      mainField: true,
    },
    {
      id: 'businessStartedThisYear',
      name: 'businessStartedThisYear',
      type: 'radio',
      options: baseRadioOptions,
      label: 'My business started this tax year',
      helpText: 'Your business started this tax year',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'businessStartDate',
            name: 'businessStartDate',
            type: 'date',
            label: 'Date',
            small: true,
          },
        ],
      },
    },
    {
      id: 'businessCloseThisYear',
      name: 'businessCloseThisYear',
      type: 'radio',
      label: 'My business ceased / closed this tax year',
      helpText: 'Your business ceased and closed this tax year',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'businessCloseDate',
            name: 'businessCloseDate',
            type: 'date',
            label: 'Date',
            small: true,
          },
        ],
      },
    },
    {
      id: 'isFosterCarer',
      name: 'isFosterCarer',
      type: 'radio',
      label: 'I am a foster carer or shared lives carer',
      helpText: 'If you are a foster carer',
      mainField: true,
      options: baseRadioOptions,
    },
  ],
  validation: validate({
    businessDescription: requiredString,
    booksDate: requiredString,

    businessAddressSameAsPersonal: requiredString,
    businessAddress: conditionalString('businessAddressSameAsPersonal', 'no'),
    businessCity: conditionalString('businessAddressSameAsPersonal', 'no'),
    businessPostCode: conditionalString('businessAddressSameAsPersonal', 'no'),

    businessAddressChanged: requiredString,

    businessStartedThisYear: requiredString,
    businessStartDate: conditionalString('businessStartedThisYear', 'yes'),

    businessCloseThisYear: requiredString,
    businessCloseDate: conditionalString('businessCloseThisYear', 'yes'),

    isFosterCarer: requiredString,
  }),
};

const rental = {
  fields: [
    {
      id: 'isShortTermHolidayLet',
      name: 'isShortTermHolidayLet',
      type: 'radio',
      options: baseRadioOptions,
      label: 'Is this a furnished short term holiday let?',
      helpText:
        "A short term holiday let is when you have a property that you let to people who are 'on holiday' and these lets tend to be short term in nature. Typically short term holiday lets are advertised on sites like Airbnb or VRBO.",
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'propertyLocation',
            name: 'propertyLocation',
            type: 'select',
            options: [
              {
                id: '1',
                label: 'United Kingdom',
                value: 'United Kingdom',
              },
              {
                id: '2',
                label: 'European Economic Area (EEA)',
                value: 'European Economic Area (EEA)',
              },
              {
                id: '3',
                label: 'Other',
                value: 'Other',
              },
            ],
            label: 'Where is this property located?',
          },
          {
            id: 'daysAvailable',
            name: 'daysAvailable',
            type: 'text',
            suffix: 'Days',
            small: true,
            label:
              'How many days was the property available for letting as furnished holiday accommodation for in the year?',
            helpText:
              'A property is not considered available if you are staying at the property.',
          },
          {
            id: 'daysLet',
            name: 'daysLet',
            type: 'text',
            suffix: 'Days',
            small: true,
            label:
              'How many days was the let commercially as furnished holiday accommodation for in the year?',
            helpText:
              'A property is not considered let commercially when you let it for more than 31 days at a time or when you allow friends or relatives stay at zero or reduced rates.',
          },
          {
            id: 'totalLongTermLetExceedLimit',
            name: 'totalLongTermLetExceedLimit',
            type: 'radio',
            options: baseRadioOptions,
            label:
              'Is the total of all lettings that exceed 31 continuous days more than 155 days?',
          },
        ],
      },
      altSubFields: {
        value: 'no',
        fields: [
          {
            id: 'isLiveInLandLord',
            name: 'isLiveInLandLord',
            type: 'radio',
            options: baseRadioOptions,
            label: 'I make rental income as a ‘Live-in Landlord’',
            helpText:
              'You’re a resident landlord if you let out part of a property which is your only or main home.',
          },
          {
            id: 'eligableForRentARoomRelief',
            name: 'eligableForRentARoomRelief',
            type: 'radio',
            options: baseRadioOptions,
            label: 'I am eligible for rent-a-room relief',
            helpText:
              'The Rent a Room Scheme lets you earn up to a threshold of £7,500 per year tax-free from letting out furnished accommodation in your home. This is halved if you share the income with your partner or someone else. The tax exemption is automatic if you earn less than the threshold. This means you don’t need to do anything. You must complete a tax return if you earn more than the threshold.',
          },
        ],
      },
    },
  ],
  validation: validate({
    isShortTermHolidayLet: requiredString,

    propertyLocation: conditionalString('isShortTermHolidayLet', 'yes'),
    daysAvailable: conditionalNumber('isShortTermHolidayLet', 'yes'),
    daysLet: conditionalNumber('isShortTermHolidayLet', 'yes'),
    totalLongTermLetExceedLimit: conditionalString(
      'isShortTermHolidayLet',
      'yes',
    ),

    isLiveInLandLord: conditionalString('isShortTermHolidayLet', 'no'),
    eligableForRentARoomRelief: conditionalString(
      'isShortTermHolidayLet',
      'no',
    ),
  }),
};

const freelanceExpenses = {
  fields: [
    {
      id: 'title1',
      name: 'title1',
      type: 'title',
      label: 'Cost of goods',
    },
    {
      id: 'hasBoughtGoods',
      name: 'hasBoughtGoods',
      type: 'radio',
      options: baseRadioOptions,
      label: 'Do you have materials bought in conjunction with your product?',
      helpText:
        'You need to buy services and / or materials to create or build your product or service.',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'allGoodsBoughtUsedLastYear',
            name: 'allGoodsBoughtUsedLastYear',
            type: 'radio',
            options: baseRadioOptions,
            label: 'All goods bought for my business were used in the tax year',
            helpText:
              'You have materials or services that were bought but have not been used for this tax year.',
            subFields: {
              value: 'no',
              fields: [
                {
                  id: 'hasUnsoldGoods',
                  name: 'hasUnsoldGoods',
                  type: 'radio',
                  options: baseRadioOptions,
                  label:
                    'I have goods or raw materials I bought for resale this year but did not sell',

                  helpText:
                    'You have materials or services that were bought but have not been used for this tax year.',
                },
                {
                  id: 'unsoldGoodsAmount',
                  name: 'unsoldGoodsAmount',
                  type: 'text',
                  label: 'Total cost of goods bought for re-sale but not used',
                  prefix: '£',
                  small: true,
                },
              ],
            },
          },
          {
            id: 'hasLastYearsGoodsSoldThisYear',
            name: 'hasLastYearsGoodsSoldThisYear',
            type: 'radio',
            options: baseRadioOptions,
            label:
              'I have goods or raw materials I bought for resale last year and sold this year',

            helpText:
              'You have materials or services that were bought last tax year and used for re-sale this tax year. ',
            subFields: {
              value: 'yes',
              fields: [
                {
                  id: 'lastYearsGoodsSoldThisYearAmount',
                  name: 'lastYearsGoodsSoldThisYearAmount',
                  type: 'text',
                  label:
                    'Total cost of goods bought for re-sale last year and used this year',
                  prefix: '£',
                  small: true,
                },
              ],
            },
          },
        ],
      },
    },

    {
      id: 'title2',
      name: 'title2',
      type: 'title',
      label: 'Travel expenses',
    },
    {
      id: 'travelForBusiness',
      name: 'travelForBusiness',
      label: 'Do you drive your personal vehicle for business?',
      type: 'radio',
      options: baseRadioOptions,
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'isMainVehicleAMotocycle',
            name: 'isMainVehicleAMotocycle',
            type: 'radio',
            options: baseRadioOptions,
            label: 'My main vehicle is a motorcycle',
            helpText: 'Your main vehicle is a motorcycle.',
          },
          {
            id: 'milesDrivenForBusiness',
            name: 'milesDrivenForBusiness',
            type: 'text',
            label: 'Miles driven for business',
            small: true,
            suffix: 'miles',
          },
          {
            id: 'travelNotification',
            name: 'travelNotification',
            type: 'notification',
            label:
              'Note: You cannot write off gas purchases if you use the miles driven option.',
          },
        ],
      },
    },

    {
      id: 'title3',
      name: 'title3',
      type: 'title',
      label: 'Staff cost',
    },
    {
      id: 'hasSubContractorExpenses',
      name: 'hasSubContractorExpenses',
      type: 'radio',
      options: baseRadioOptions,
      label: 'I have staff or subcontractor expenses',
      helpText: 'I paid staff or contractors for their services.',
      mainField: true,
    },
    {
      id: 'title4',
      name: 'title4',
      type: 'title',
      label: 'Rent and rates',
    },
    {
      id: 'workFromHome',
      name: 'workFromHome',
      type: 'radio',
      options: baseRadioOptions,
      label: 'I work from home and want to expense part of my home expenses',
      helpText:
        'If you work from home, you may be able to claim a proportion of your costs for things like: heating, electricity, Council Tax, mortgage interest or rent, internet and telephone use',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'totalHoursWorkingFromHomePerMonth',
            name: 'totalHoursWorkingFromHomePerMonth',
            type: 'text',
            label: 'Total hours working from home per month',
            small: true,
            suffix: 'hours',
            helpText:
              'Working from home full time is approximately 160 hours per month.',
          },
          {
            id: 'percentageHomeUtilitiesUsedForBusiness',
            name: 'percentageHomeUtilitiesUsedForBusiness',
            type: 'text',
            label:
              'What percentage of your home and utilities are used for business?',
            small: true,
            suffix: '%',
            helpText:
              'You can calculate the percentage by using the room calculation (room used for work / total rooms in the house) or the total sq. ft calculation (sq ft of the house used for work / total sq ft of the house). In both instances, you should multiply the answer by the percentage of time the space is used for work versus personal.',
          },
        ],
      },
    },
  ],
  validation: validate({
    hasBoughtGoods: requiredString,
    allGoodsBoughtUsedLastYear: conditionalString('hasBoughtGoods', 'yes'),
    hasUnsoldGoods: conditionalString('allGoodsBoughtUsedLastYear', 'no'),
    unsoldGoodsAmount: conditionalNumber('allGoodsBoughtUsedLastYear', 'no'),
    hasLastYearsGoodsSoldThisYear: conditionalString('hasBoughtGoods', 'yes'),
    lastYearsGoodsSoldThisYearAmount: conditionalNumber(
      'hasLastYearsGoodsSoldThisYear',
      'yes',
    ),

    travelForBusiness: requiredString,
    isMainVehicleAMotocycle: conditionalString('travelForBusiness', 'yes'),
    milesDrivenForBusiness: conditionalNumber('travelForBusiness', 'yes'),

    hasSubContractorExpenses: requiredString,

    workFromHome: requiredString,
    totalHoursWorkingFromHomePerMonth: conditionalNumber('workFromHome', 'yes'),
    percentageHomeUtilitiesUsedForBusiness: conditionalNumber(
      'workFromHome',
      'yes',
    ),
  }),
};

const paye = {
  fields: [
    {
      id: 'name',
      name: 'name',
      label: 'Employer Name',
      type: 'text',
    },
    {
      id: 'payeTaxReference',
      name: 'payeTaxReference',
      label: 'PAYE tax reference of employer',
      type: 'text',
      small: true,
      hintText: 'This can be found on your payslip',
      helpText:
        'From your P45/P60 (It’ll usually look something like 123/A45678)',
    },
    {
      id: 'totalPayBeforeTax',
      name: 'totalPayBeforeTax',
      label: 'Total Pay (before tax)',
      type: 'text',
      prefix: '£',
      small: true,
    },
    {
      id: 'taxDeducted',
      name: 'taxDeducted',
      label: 'Tax Deducted (box 1)',
      type: 'text',
      prefix: '£',
      small: true,
    },
    {
      id: 'finalTaxCode',
      name: 'finalTaxCode',
      label: 'Final Tax Code',
      type: 'text',
      small: true,
    },
    {
      id: 'niContributions',
      name: 'niContributions',
      label: 'NI Contributions (above PT)',
      type: 'text',
      prefix: '£',
      small: true,
    },
    {
      id: 'receiveBenefits',
      name: 'receiveBenefits',
      type: 'radio',
      options: baseRadioOptions,
      label: 'I received benefits from this employment',
      hintText: 'Check your P11D form',
      helpText:
        'You received benefits from your employer that are detailed on your P11D form.',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'companyCar',
            name: 'companyCar',
            label: 'Company cars and vans',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'fuelForCar',
            name: 'fuelForCar',
            label: 'Fuel for company cars and vans',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'privateInsurance',
            name: 'privateInsurance',
            label: 'Private medical and dental insurance',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'vouchersCreditCards',
            name: 'vouchersCreditCards',
            label: 'Vouchers credit cards and excess mileage',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'goodsAndOtherAssets',
            name: 'goodsAndOtherAssets',
            label: 'Goods and other assets provided by employer',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'accommodations',
            name: 'accommodations',
            label: 'Accommodation provided by employer',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'otherBenefits',
            name: 'otherBenefits',
            label: 'Other benefits ',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'expensesPaymentsReceived',
            name: 'expensesPaymentsReceived',
            label: 'Expenses payments received and balancing charges',
            type: 'text',
            prefix: '£',
            small: true,
          },
        ],
      },
    },

    {
      id: 'claimExpenses',
      name: 'claimExpenses',
      type: 'radio',
      options: baseRadioOptions,
      label: 'I want to claim expenses from this employment',
      helpText:
        'You can claim expenses that were not reimbursed from your employment.',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'businsesTravel',
            name: 'businsesTravel',
            label: 'Business travel and subsistence expenses',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'fixedDeductionsForExpenses',
            name: 'fixedDeductionsForExpenses',
            label: 'fixed deductions for expenses ',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'professionalFees',
            name: 'professionalFees',
            label: 'Professional fees and subscriptions',
            type: 'text',
            prefix: '£',
            small: true,
          },
          {
            id: 'otherExpenses',
            name: 'otherExpenses',
            label: 'Other expenses and capital allowances',
            type: 'text',
            prefix: '£',
            small: true,
          },
        ],
      },
    },
    {
      id: 'hasReceivedOtherIncome',
      name: 'hasReceivedOtherIncome',
      type: 'radio',
      options: baseRadioOptions,
      mainField: true,
      label:
        'I received other income from my PAYE employment that is not listed on the P45 or P60 (ex: tips)',

      helpText:
        'You received income from employment that is not listed on the P45 or P60. Example: tips',
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'otherIncomeAmount',
            name: 'otherIncomeAmount',
            label: 'Tips and other payments not on p60',
            type: 'text',
            prefix: '£',
            small: true,
          },
        ],
      },
    },
    {
      id: 'isOnPaypaymentOfTeachersLoanScheme',
      name: 'isOnPaypaymentOfTeachersLoanScheme',
      label: 'Part time teacher in England or Wales with Teach loan scheme',
      type: 'radio',
      options: baseRadioOptions,
      mainField: true,
    },
  ],
  validation: validate({
    name: requiredString,
    payeTaxReference: requiredString,
    totalPayBeforeTax: requiredNumber,
    taxDeducted: requiredNumber,
    finalTaxCode: requiredString,
    niContributions: requiredNumber,

    receiveBenefits: requiredString,
    companyCar: conditionalNumber('receiveBenefits', 'yes'),
    fuelForCar: conditionalNumber('receiveBenefits', 'yes'),
    privateInsurance: conditionalNumber('receiveBenefits', 'yes'),
    vouchersCreditCards: conditionalNumber('receiveBenefits', 'yes'),
    goodsAndOtherAssets: conditionalNumber('receiveBenefits', 'yes'),
    accommodations: conditionalNumber('receiveBenefits', 'yes'),
    otherBenefits: conditionalNumber('receiveBenefits', 'yes'),
    expensesPaymentsReceived: conditionalNumber('receiveBenefits', 'yes'),

    claimExpenses: requiredString,
    businsesTravel: conditionalNumber('claimExpenses', 'yes'),
    fixedDeductionsForExpenses: conditionalNumber('claimExpenses', 'yes'),
    professionalFees: conditionalNumber('claimExpenses', 'yes'),
    otherExpenses: conditionalNumber('claimExpenses', 'yes'),

    hasReceivedOtherIncome: requiredString,
    otherIncomeAmount: conditionalNumber('hasReceivedOtherIncome', 'yes'),

    isOnPaypaymentOfTeachersLoanScheme: requiredString,
  }),
};

const investment = {
  fields: [
    {
      id: 'assetType',
      name: 'assetType',
      type: 'select',
      label: 'Asset type',
      options: [
        {
          id: '1',
          label: 'Residential Property',
          value: 'Residential Property',
        },
        {
          id: '2',
          label: 'Other Property, assets and gains',
          value: 'Other Property, assets and gains',
        },
        {
          id: '3',
          label: 'Listed Shares or Securities',
          value: 'Listed Shares or Securities',
        },
        {
          id: '4',
          label: 'Unlisted Shares or Securities',
          value: 'Unlisted Shares or Securities',
        },
      ],
    },
    {
      id: 'assetDescription',
      name: 'assetDescription',
      type: 'textarea',
      label: 'Asset description',
    },
    {
      id: 'purchaseDate',
      name: 'purchaseDate',
      type: 'date',
      label: 'Purchase date',
      small: true,
    },
    {
      id: 'saleDate',
      name: 'saleDate',
      type: 'date',
      label: 'Sale date',
      small: true,
    },
    {
      id: 'purchaseAmount',
      name: 'purchaseAmount',
      type: 'text',
      label: 'Purchase amount',
      prefix: '£',
      small: true,
    },
    {
      id: 'saleAmount',
      name: 'saleAmount',
      type: 'text',
      label: 'Sale amount',
      prefix: '£',
      small: true,
    },

    {
      id: 'expenses',
      name: 'expenses',
      type: 'text',
      label: 'Expenses',
      prefix: '£',
    },
    {
      id: 'investmentTotal',
      name: 'investmentTotal',
      type: 'investmentTotal',
      label: 'Investment Total',
      prefix: '£',
    },
    {
      id: 'expensesDescription',
      name: 'expensesDescription',
      type: 'textarea',
      label: 'Expenses description',
    },
    {
      id: 'hasAlreadyFiledAReturnForThisTransaction',
      name: 'hasAlreadyFiledAReturnForThisTransaction',
      type: 'radio',
      options: baseRadioOptions,
      label: 'I already filed a return for this transaction',
      helpText:
        'You have already filed a return, told HMRC about this transaction and paid tax.',
      mainField: true,
      subFields: {
        value: 'yes',
        fields: [
          {
            id: 'taxAmountCharged',
            name: 'taxAmountCharged',
            type: 'text',
            label: 'Tax amount charged',
            prefix: '£',
            small: true,
          },
        ],
      },
    },
  ],
  validation: validate({
    purchaseDate: requiredString,
    saleDate: requiredString,
    purchaseAmount: requiredNumber,
    saleAmount: requiredNumber,
    assetType: requiredString,
    assetDescription: requiredString,
    expenses: requiredNumber,
    expensesDescription: requiredString,
    hasAlreadyFiledAReturnForThisTransaction: requiredString,
    taxAmountCharged: conditionalNumber(
      'hasAlreadyFiledAReturnForThisTransaction',
      'yes',
    ),
  }),
};

const transactionFormFields = {
  fields: [
    {
      id: 'madeOn',
      name: 'madeOn',
      label: 'Date of transaction',
      type: 'date',
    },
    {
      id: 'merchantName',
      name: 'merchantName',
      label: 'Payee',
      type: 'text',
      helpText:
        'A payee is the person, business or merchant that you paid or that paid you.',
    },
    {
      id: 'description',
      name: 'description',
      label: 'Description',
      type: 'text',
    },
    {
      id: 'transactionType',
      name: 'transactionType',
      label: 'Transaction type',
      type: 'select',
      options: [
        {
          id: '1',
          label: 'Income',
          value: 'CREDIT',
        },
        {
          id: '2',
          label: 'Expense',
          value: 'DEBIT',
        },
      ],
    },
    {
      id: 'amount',
      name: 'amount',
      label: 'Amount',
      type: 'transaction',
    },
  ],
  validation: validate({
    madeOn: requiredString,
    merchantName: requiredString,
    description: requiredString,
    transactionType: requiredString,
    amount: requiredNumber,
  }),
};

const documentFormFields = [
  {
    id: 'documentGroup',
    name: 'documentGroup',
    label: 'Document Type',
    type: 'select',
    options: [
      {
        id: '1',
        label: 'Proof of ID',
        value: 'Proof of ID',
      },
      {
        id: '2',
        label: 'Proof of Address',
        value: 'Proof of Address',
      },
      {
        id: '3',
        label: 'P45 or P60',
        value: 'P45 or P60',
      },
      {
        id: '4',
        label: 'P11D',
        value: 'P11D',
      },
      {
        id: '5',
        label: 'Receipt',
        value: 'Receipt',
      },
      {
        id: '6',
        label: 'Invoice',
        value: 'Invoice',
      },
      {
        id: '7',
        label: 'Signed rental agreement',
        value: 'Signed rental agreement',
      },
      {
        id: '8',
        label: 'Proof of Investment',
        value: 'Proof of Investment',
      },
    ],
  },
  {
    id: 'job',
    name: 'job',
    label: 'Income Source',
    type: 'job',
  },
  {
    id: 'description',
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },
  {
    id: 'file',
    name: 'file',
    type: 'file',
    hintText: 'Supported file types: Image, PDF, Excel or CSV',
  },
  {
    id: 'uri',
    name: 'uri',
    type: 'image',
  },
];

const referalCodeForm = {
  fields: [
    {
      id: 'referral',
      name: 'referral',
      label: 'Referral code',
      type: 'text',
    },
  ],
  validation: validate({
    referral: requiredString,
  }),
};

const inviteForm = [
  {
    id: 'message',
    name: 'message',
    label: 'Personalised message',
    type: 'textarea',
  },
  {
    id: 'email',
    name: 'email',
    type: 'text',
    label: 'Email',
  },
];

const ruleFormFields = {
  fields: [
    {
      id: 'description',
      name: 'description',
      label: 'Transaction description',
      type: 'infoText',
      readOnly: 'true',
    },
    {
      id: 'transactionType',
      name: 'transactionType',
      label: 'Auto tag transactions',
      type: 'radio',
      options: [
        {
          id: '1',
          label: 'Income',
          value: 'CREDIT',
        },
        {
          id: '2',
          label: 'Expense',
          value: 'DEBIT',
        },
      ],
    },
    {
      id: 'job',
      name: 'job',
      type: 'tag',
    },
  ],
  validation: validate({
    transactionType: requiredString,
    job: requiredString,
  }),
};

const editRuleFormFields = {
  fields: [
    {
      id: 'match',
      name: 'match',
      label: 'Transaction description',
      type: 'infoText',
      readOnly: 'true',
    },
    {
      id: 'ruleType',
      name: 'ruleType',
      label: 'Auto tag transactions',
      type: 'radio',
      options: [
        {
          id: '1',
          label: 'Income',
          value: 'INCOME',
        },
        {
          id: '2',
          label: 'Expense',
          value: 'EXPENSE',
        },
      ],
    },
    {
      id: 'job',
      name: 'job',
      type: 'tag',
    },
  ],
  validation: validate({
    ruleType: requiredString,
    job: requiredString,
  }),
};

export {
  loginForm,
  resetForm,
  registerForm,
  registerFormLanding,
  changePasswordForm,
  profileForm,
  overviewProfileForm,
  taxSettingsForm,
  businessSettingsForm,
  investment,
  taxRelief,
  freelanceExpenses,
  freelance,
  paye,
  rental,
  transactionFormFields,
  documentFormFields,
  referalCodeForm,
  inviteForm,
  ruleFormFields,
  optimiserStep1Form,
  optimiserStep1WithNameForm,
  optimiserStep2Form,
  optimiserStep3Form,
  optimiserStep4Form,
  optimiserStep4FormWithoutName,
  editRuleFormFields,
};
